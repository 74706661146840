<template>
  <div>
    <el-card :body-style="{ padding: '25px' }">
      <div slot="header">
        <div style="margin-left: 0px; display: flex">
          <div class="select2">
            <el-select
              v-model="selectTipoBusqueda"
              slot="prepend"
              style="width:140px"
            >
              <el-option label="Nombre" :value="1"></el-option>
              <el-option label="Habilitado" :value="2"></el-option>
            </el-select>
          </div>
          <div class="buscador2">
            <!-- Nombre -->
            <maca-input-delay
              v-show="selectTipoBusqueda == 1"
              v-model="nombreBusqueda"
              icon="el-icon-search"
              placeholder="Buscar producto"
            ></maca-input-delay>
            <!-- Habilitado -->
            <el-select
              v-show="selectTipoBusqueda == 2"
              v-model="habilitadoBusqueda"
              slot="prepend"
              style="width:150px"
              @change="actualizarTablaFuncion()"
            >
              <el-option label="SI" :value="1"></el-option>
              <el-option label="NO" :value="0"></el-option>
            </el-select>
          </div>
        </div>
        <el-button
          class="botonmasIcono"
          icon="el-icon-plus"
          type="primary"
          round
          style="margin-right: 0px"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo</el-button
        >
      </div>
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="70" label="Imagen">
            <template slot-scope="props">
              <el-image
                style="width: 120px"
                :src="props.row.urlImagen"
                fit="contain"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" label="Nombre">
            <template slot-scope="props">
              <span v-text="props.row.nombre"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" label="Descripción">
            <template slot-scope="props">
              <span v-text="props.row.descripcion"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="120" label="Precio">
            <template slot-scope="props">
              <span>{{ "$" + props.row.precio }} </span>
            </template>
          </el-table-column>
          <el-table-column label="Habilitado" width="90" align="center">
            <template slot-scope="props">
              <el-switch
                v-model="props.row.habilitado"
                :active-value="1"
                :inactive-value="0"
                @change="toogleHabilitado(props.row)"
                :disabled="
                  props.row.bloqueado
                    ? !$store.getters.tienePermiso('M_USE_DES')
                    : !$store.getters.tienePermiso('M_USE_BLO')
                "
              ></el-switch>
            </template>
          </el-table-column>
         <!--  <el-table-column label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                type="primary"
                circle
                @click="$refs.modalModificar.abrir(props.row.id)"
                :disabled="!$store.getters.tienePermiso('M_USE')"
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column> -->
          <el-table-column label="Borrar" width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                @click="eliminar(props.row.id)"
                circle
                :disabled="!$store.getters.tienePermiso('B_USE')"
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-nuevo
      ref="modalNuevo"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "producto",
  components: {
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: "/producto/obtenerTodos",
      paramsTabla: {},
      actualizarTabla: true,
      bloquearTabla: true,
      filtroDescripcion: null,
      nombreBusqueda: "",
      selectTipoBusqueda: 1,
      habilitadoBusqueda: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el producto."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { id: id };

      let respuestaApi = await this.$maca.api.post(
        "/producto/eliminar",
        params
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Producto borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    actualizarTablaFuncion() {
      if (this.selectTipoBusqueda == 2) {
        this.paramsTabla = { habilitado: this.habilitadoBusqueda };
      } else {
        this.paramsTabla = {};
      }
      this.actualizarTabla = true;
    },
    async toogleHabilitado(fila) {
      this.bloquearTabla = true;

      // Hacer post
      let params = {
        id: fila.id,
        habilitado: fila.habilitado,
        descripcion: fila.descripcion,
        nombre: fila.nombre,
        precio: fila.precio,
      };
      let respuestaApi = null;

      respuestaApi = await this.$maca.api.post("/producto/actualizar", params);
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado != 1) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    nombreBusqueda() {
      if (this.selectTipoBusqueda == 1) {
        this.paramsTabla = { nombre: this.nombreBusqueda };
      } else {
        this.paramsTabla = {};
      }
      this.actualizarTabla = true;
    },
  },
};
</script>
