var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-card", { attrs: { "body-style": { padding: "25px" } } }, [
        _c(
          "div",
          { attrs: { slot: "header" }, slot: "header" },
          [
            _c(
              "div",
              { staticStyle: { "margin-left": "0px", display: "flex" } },
              [
                _c(
                  "div",
                  { staticClass: "select2" },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "140px" },
                        attrs: { slot: "prepend" },
                        slot: "prepend",
                        model: {
                          value: _vm.selectTipoBusqueda,
                          callback: function($$v) {
                            _vm.selectTipoBusqueda = $$v
                          },
                          expression: "selectTipoBusqueda"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "Nombre", value: 1 }
                        }),
                        _c("el-option", {
                          attrs: { label: "Habilitado", value: 2 }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "buscador2" },
                  [
                    _c("maca-input-delay", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selectTipoBusqueda == 1,
                          expression: "selectTipoBusqueda == 1"
                        }
                      ],
                      attrs: {
                        icon: "el-icon-search",
                        placeholder: "Buscar producto"
                      },
                      model: {
                        value: _vm.nombreBusqueda,
                        callback: function($$v) {
                          _vm.nombreBusqueda = $$v
                        },
                        expression: "nombreBusqueda"
                      }
                    }),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.selectTipoBusqueda == 2,
                            expression: "selectTipoBusqueda == 2"
                          }
                        ],
                        staticStyle: { width: "150px" },
                        attrs: { slot: "prepend" },
                        on: {
                          change: function($event) {
                            return _vm.actualizarTablaFuncion()
                          }
                        },
                        slot: "prepend",
                        model: {
                          value: _vm.habilitadoBusqueda,
                          callback: function($$v) {
                            _vm.habilitadoBusqueda = $$v
                          },
                          expression: "habilitadoBusqueda"
                        }
                      },
                      [
                        _c("el-option", { attrs: { label: "SI", value: 1 } }),
                        _c("el-option", { attrs: { label: "NO", value: 0 } })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _c(
              "el-button",
              {
                staticClass: "botonmasIcono",
                staticStyle: { "margin-right": "0px" },
                attrs: { icon: "el-icon-plus", type: "primary", round: "" },
                on: {
                  click: function($event) {
                    return _vm.$refs.modalNuevo.abrir()
                  }
                }
              },
              [_vm._v("Nuevo")]
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { "min-width": 70, label: "Imagen" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-image", {
                            staticStyle: { width: "120px" },
                            attrs: { src: props.row.urlImagen, fit: "contain" }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 120, label: "Nombre" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: { textContent: _vm._s(props.row.nombre) }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 120, label: "Descripción" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(props.row.descripcion)
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { "min-width": 120, label: "Precio" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s("$" + props.row.precio) + " ")
                          ])
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Habilitado", width: "90", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              disabled: props.row.bloqueado
                                ? !_vm.$store.getters.tienePermiso("M_USE_DES")
                                : !_vm.$store.getters.tienePermiso("M_USE_BLO")
                            },
                            on: {
                              change: function($event) {
                                return _vm.toogleHabilitado(props.row)
                              }
                            },
                            model: {
                              value: props.row.habilitado,
                              callback: function($$v) {
                                _vm.$set(props.row, "habilitado", $$v)
                              },
                              expression: "props.row.habilitado"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "Borrar", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "danger",
                                circle: "",
                                disabled: !_vm.$store.getters.tienePermiso(
                                  "B_USE"
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.eliminar(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-delete" })]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }